import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useAppState } from "../AppStateContext";
import { Container, Row, Col } from "reactstrap";

const Landing = () => {
  const { isAuthenticated } = useAppState();

  return (
    <div
      className="landing-page"
      style={{ backgroundColor: "black", minHeight: "100vh", color: "white" }}
    >
      <div className="text-center py-5" style={{ backgroundColor: "black" }}>
        <div className="container">
          <pre className="ascii-art" style={{ color: "white" }}>
            {`
'  ████████ ███████ ██████  
'     ██    ██      ██   ██ 
'     ██    █████   ██████  
'     ██    ██      ██   ██ 
'     ██    ██      ██████  
'                           
'                           
      `}
          </pre>
          <p className="lead mb-4" style={{ color: "white" }}>
            The only betting research community you'll ever need.
          </p>
          {!isAuthenticated ? (
            <Link to="/register" className="btn btn-primary btn-lg">
              Join the beta
            </Link>
          ) : (
            <p>Welcome back!</p>
          )}
        </div>
      </div>

      <Container>
        <Row className="my-5">
          <Col xs={12} md={10} lg={10} xl={12} className="mx-auto">
            <h2 className="mb-4">The Pitch</h2>
            <p>
              Most bettors rely on instinct and intuition to make decisions.
              Instinct should be augmented with cold hard data. You need the
              power of machine learning and AI to confirm your instincts. On top
              of those, you need the collective power and insight of other
              informed bettors to make sure that you're making the right
              decision, even if it doesn't hit this time.

              <br />
              <br />
              If you're seeing this, then you're part of the friends and family beta. 
              Just register an account and you'll have access to the premium features for free for life!

              We plan on launching and fully integrating the Stripe payment system at some point in November 2024.
            </p>
          </Col>
        </Row>

        <hr className="my-5" style={{ borderColor: 'white' }} />

        <Row className="my-5">
          <Col xs={12} md={10} lg={8} xl={12} className="mx-auto">
            <h2 className="mb-4">Data Cheat Sheet</h2>
            <p className="mb-4">
              Dive into a treasure trove of betting insights with our
              comprehensive data cheat sheet. Whether you're a casual bettor or
              a seasoned sharp, our platform offers the depth and clarity you
              need to make informed decisions. The best part is that it's free!
            </p>

            <h4 className="mt-5 mb-3">Detailed Performance Breakdowns</h4>
            <ul>
              <li className="mb-3">
                Recent Form Analysis:
                <ul>
                  <li>Track team performance over the last 3, 5, and 10 games</li>
                  <li>View win-loss records, including draws for applicable sports</li>
                  <li>Analyze point differentials to gauge team strength</li>
                </ul>
              </li>
              <li className="mb-3">
                Spread Coverage:
                <ul>
                  <li>See how often teams cover the spread in recent games and throughout the season</li>
                  <li>Compare home and away spread performance</li>
                  <li>Identify trends in spread coverage for more accurate predictions</li>
                </ul>
              </li>
              <li className="mb-3">
                Over/Under Insights:
                <ul>
                  <li>Track total points scored in recent games</li>
                  <li>View the frequency of overs and unders for each team</li>
                  <li>Compare team totals against current over/under lines</li>
                </ul>
              </li>
            </ul>

            <h4 className="mt-5 mb-3">Head-to-Head Matchup Analysis</h4>
            <ul>
              <li>Comprehensive history of previous encounters</li>
              <li>Detailed stats for wins, spreads, and totals in head-to-head games</li>
            </ul>

            <h4 className="mt-5 mb-3">Season-Long Statistics</h4>
            <ul>
              <li>Full season records for wins, losses, and draws</li>
              <li>Home and away performance breakdowns</li>
              <li>Season-long spread coverage and over/under trends</li>
              <li>Previous season data for year-over-year comparisons (available for the first 25% of the season)</li>
            </ul>

            <h4 className="mt-5 mb-3">Advanced Metrics</h4>
            <ul>
              <li className="mb-3">
                ELO Ratings: Understand team strength beyond simple win-loss records
                <ul>
                  <li>Our ELO Ratings are tuned using Bayesian optimization to provide the most accurate ratings possible</li>
                </ul>
              </li>
              <li>Point Differential Ratings: Gauge team quality based on scoring margins</li>
            </ul>

            <h4 className="mt-5 mb-3">Interactive and User-Friendly Interface</h4>
            <ul>
              <li>Toggle between raw numbers, percentages, and intuitive star ratings by tapping the table</li>
              <li>Mobile-responsive design for fast on-the-go analysis</li>
            </ul>

            <p className="mt-5">
              From quick pre-game checks to deep statistical dives, our data
              cheat sheet equips you with all the information you need. Make
              more informed bets, validate your hunches with hard data, and gain
              an edge in your sports betting journey. Enhance your casual
              betting experience or fine-tune your professional strategy! Our
              platform has you covered.
            </p>
          </Col>
        </Row>

        <hr className="my-5" style={{ borderColor: 'white' }} />

        <Row className="my-5">
          <Col xs={12} md={10} lg={8} xl={12} className="mx-auto">
            <h2 className="mb-4">Premium Features</h2>
            <p className="mb-4">
              Be an early adopter and lock in a lifetime price of $25 a month.
              Our premium features are designed to give you a significant edge
              in your betting endeavors.
            </p>

            <h4 className="mt-5 mb-3">Advanced Projection Models</h4>
            <p>
              Get access to projections and plus-EV bets from three
              sophisticated models:
            </p>
            <ul>
              <li>Model A: Neural Network</li>
              <li>Model B: SVC</li>
              <li>Model C: Gradient Boosting</li>
            </ul>
            <p className="mt-3">
              Our models are trained extensively on historical data and have
              been validated to have a slight edge over the market. Compare
              projections across these models to identify consensus picks and
              potential value bets.
            </p>

            <h4 className="mt-5 mb-3">Positive Expected Value (EV) Betting</h4>
            <p>
              Our platform identifies positive EV bets, where the probability of
              an outcome occurring is higher than what the odds suggest. This
              strategy aims to provide a long-term edge over the market.
            </p>
            <p>
              For example, if our models project a team has a 53% chance of
              winning, but the odds imply only a 50% chance, this presents a
              positive EV betting opportunity.
            </p>
            <p>
              We auto-calculate the vig for each bet to let you know how confident
              the market is in their pricing. A higher vig indicates that the market is not 
              confident in their pricing, while a lower vig suggests that the market is more
              confident.
            </p>
          
            <h4 className="mt-5 mb-3">Confidence Rating System</h4>
            <p>
              Our intuitive star rating system reflects the models' confidence
              in each projection:
            </p>
            <ul>
              <li>⭐ : Slight or no edge, we agree with the market pricing</li>
              <li>⭐⭐ : Moderate value bet, we think it's a good bet for the price that the market offers</li>
              <li>⭐⭐⭐ : High value bet, we think it has a much higher probability of success than the market has priced it</li>
            </ul>
            <p className="mt-3">
              This system helps you quickly identify the most promising betting
              opportunities according to our models.
            </p>

            <h4 className="mt-5 mb-3">Community Insights</h4>
            <p>
              Get access to each game's discussion threads and valuable insights
              from sharps and experts through our Reddit-like comment interface:
            </p>
            <ul>
              <li>Discuss strategies and share insights on individual games</li>
              <li>Learn from experienced bettors and contribute your own analysis</li>
              <li>Upvote valuable comments to highlight the best information</li>
              <li>Track user reputation to identify consistent contributors</li>
            </ul>
            <p className="mt-3">
              This collaborative approach combines the power of data-driven
              models with human insight, helping you make more informed betting
              decisions.
            </p>

            <p className="mt-5">
              Unlock these premium features today for just $25 a month and take
              your sports betting to the next level!
            </p>
          </Col>
        </Row>
      </Container>

      <footer className="text-center py-3 mt-5">
        <p>&copy; 2024 The Form Bettor LLC. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Landing;